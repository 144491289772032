import React, { useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import PSModal from '../../components/modal';
import SyllabusForm from '../../components/forms/syllabusForm';
import Button from '../../components/buttons/linkButton';
import { Container, Col, Row } from 'react-bootstrap';
import ReactGA from 'react-ga'
import Curriculum from '../../components/curriculum'; 
import qa from '../../assets/images/qa.png'
import logo from '../../assets/images/parsity-logo-black.png'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const PrepContainer = styled.div`
  display: block;
  height: 700px;
  background: none;
  background-color: var(--grey);
  background-size: cover;
  background-position: center center;
`

const HeroContainer = styled(Container)`
  padding-top: 100px;
  max-width: 1000px;
  .logo {
    max-width: 200px;
    padding-bottom: 50px;
  }
  .col-hero-text {
    text-align: center;
    padding-left: 40px;
    h1 {
      padding-top: 20px;
      padding-bottom: 20px;
      color: var(--black);
      text-align: center;
      font-weight: bold;
      font-size: 70px;

      @media screen and (max-width: 767px) {
        font-size: 24px;
      } 
    }
    .subtext {
      padding: 40px 0 20px 0;
      color: var(--black);
      text-align: center;
    }
    .cta-syllabus {
      margin-left: 20px;
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  } 
`


const AboutSection = styled.section`
  padding-bottom: 80px;
  .support-row {
    margin-top: 80px;
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .support-text {
      font-size: 16px;
    }
  }
  p {
    padding-top: 20px;
    text-align: center;
  }
  .features-title {
    padding-top: 40px;
    text-align: center;
  }
  .btn-apply-curriculum {
    margin-bottom: 40px;
    margin-top: 40px;
  }
`

const GetStartedBox = styled.div`
  border: 4px solid var(--black);
  background: #fff;
  margin-top: 40px;
  box-shadow: -15px 15px var(--black);
`

const AboutHero = styled(Container)`
  .about-left {
    p {
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 0px;
      padding-right: 50px;
    }
    .subtext-launch {
      font-size: 18px;
      font-style: italic;
    }
  }
  .about-right {
    padding: 0;
    max-width: 1000px;
    img {
      box-shadow: -15px 15px var(--black);
      border: 5px solid var(--black);
    }
  }
`

const GetStartedSection = styled.section`
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: var(--orange);
  p {
    color: #fff;
    padding-top: 20px;
    text-align: center;
  }
  .get-started-title {
    margin-top: 40px;
    text-align: center;
    color: var(--white);

    .bold {
      font-weight: bold;
    }
  }
  .btn-apply-curriculum {
    margin-top: 40px;
  }

  .steps-list {
    line-height: 1.85714;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
  }

  .step {
    padding: 41px 55px 51px;
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
  }

  .step-text {
    color: var(--black);
    font-size: 14px;
  }

  .step-title {
    text-align: center;
  }

  .step:nth-child(3n+3):before {
    display: block;
    content: "";
    position: absolute;
    width: 200%;
    height: 1px;
    top: 0;
    left: 0;
    background: linear-gradient(
  90deg
  ,hsla(0,0%,94.9%,0) 0,hsla(0,0%,94.9%,.9) 25%,#f2f2f2 50%,hsla(0,0%,94.9%,.9) 75%,hsla(0,0%,94.9%,0));
  }

  .steps-list:after {
    display: block;
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: -webkit-gradient(linear,left top,left bottom,from(hsla(0,0%,94.9%,0)),color-stop(25%,hsla(0,0%,94.9%,.9)),color-stop(50%,#f2f2f2),color-stop(75%,hsla(0,0%,94.9%,.9)),to(hsla(0,0%,94.9%,0)));
    background: linear-gradient(
180deg
,hsla(0,0%,94.9%,0) 0,hsla(0,0%,94.9%,.9) 25%,#f2f2f2 50%,hsla(0,0%,94.9%,.9) 75%,hsla(0,0%,94.9%,0));
}
`

const Index = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => setShow(true);

  const fireGTMEvent = () => {
    ReactGA.event({
      category: 'CTA - Button',
      action: 'Click',
      label: 'waitlist',
    })
  };

  return (
    <Layout>
      <PrepContainer>
        <PSModal handleClose={handleClose} handleShow={handleShow} show={show}>
          <SyllabusForm close={handleClose} />
        </PSModal>
        <HeroContainer>
          <Row>
            <Col className='col-hero-text' md={12}>
              <img className='logo' src={logo}></img>
              <h1>Do you want a high paying tech job?</h1>
              <p className='subtext'>Parsity is determined to take you from newbie to 
job-ready Software Engineer. Are you?</p>
              <AnchorLink to="/prep#students" title="Our team">
                <Button fireGTMEvent={fireGTMEvent} text={'Get Started'} />
              </AnchorLink>
            </Col>
          </Row>
        </HeroContainer>
      </PrepContainer> 
      <AboutSection>
        <Container>
          <Row className='support-row'>
            <AboutHero fluid>
              <Row>
                <Col className='about-left' md={6}>
                  <h2>What is Parsity?</h2>
                  <p className='compare-text'>
                    Most people learning to code struggle to see it through. At Parsity, we’ve created an accessible and supportive online software engineering training program that helps students land their dream jobs in tech, within the year. <p>Additionally, we develop resources that help beginners take their first steps.</p>
                    <Button fireGTMEvent={fireGTMEvent} handleCTAClick={handleShow} text={'See Our Online Code School'} />
                  </p>
                </Col>
                <Col className='about-right' md={6}>
                  <div className="cover">
                    <img src={qa} alt=''></img>
                  </div>
                </Col>
              </Row>
            </AboutHero>
          </Row>
        </Container>
      </AboutSection>
      <GetStartedSection>
        <Container>
          <Row>
            <Col md={12}>
              <h2 className='get-started-title'>Ready <span className='bold'>to land your dream job</span>? Just follow the 4 steps below_</h2>
              <GetStartedBox>
                <div>
                <ul class="size-14 steps-list">
                  <li class="step">
                    <h3 className='step-title'>Step #1</h3>
                    <p className='step-text'>If coding isn't already part of your weekly schedule, read this step by step tutorial, and along with our provided template, build your schedule.</p>
                    <Button fireGTMEvent={fireGTMEvent} text={'Build Your Schedule'} />
                  </li>
                  <li class="step">
                    <h3 className='step-title'>Step #2</h3>
                    <p className='step-text'>Learn JavaScript! It is the place to start and we created a free, premium course.</p>
                    <Button fireGTMEvent={fireGTMEvent} text={'Learn JavaScript'} />
                  </li>
                  <li class="step">
                    <h3 className='step-title'>Step #3</h3>
                    <p className='step-text'>Learn more about what it takes to land your first software engineering job by listening to our weekly podcast.</p>
                    <Button fireGTMEvent={fireGTMEvent} text={'Listen to Podcast'} />
                  </li>
                  <li class="step">
                    <h3 className='step-title'>Step #4</h3>
                    <p className='step-text'>Apply for our Full Stack code school, which is designed to help you land your frist Software Engineering job.</p>
                    <Button fireGTMEvent={fireGTMEvent} text={'Apply for Parsity'} />
                  </li>
                </ul>
              </div>
              </GetStartedBox> 
            </Col>
          </Row>
        </Container>
      </GetStartedSection>
    </Layout>
  )
}

export default Index
